import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from '../../animations';
import { AppConfig } from '../../config/config';
import Swal from 'sweetalert2';
import { UserModel } from '../../Infogroup-app/models/user';
import { CommonService } from '../../Infogroup-app/services/common.service';
import { UserService } from '../../Infogroup-app/services/user.service';

declare var $: any;

@Component({
  selector: 'app-VW',
  templateUrl: './infogroup-layout.component.html',
  animations: [
    slideInAnimation
  ]
})

export class InfogroupLayoutComponent implements OnInit, AfterViewChecked {

  menu: any;
  grpUrl = this.config.setting['grpUrl'];
  user: UserModel;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  constructor(
    private commonSrv: CommonService,
    private config: AppConfig,
    private changeRef: ChangeDetectorRef,
    private authService: UserService,
    @Inject(DOCUMENT) private document: Document) {

  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  ngOnInit() {

    this.authService.getUserInfo().subscribe(resp => {
      this.user = resp;
      this.authService.setUserData(resp);
    });

    const that = this;

    this.authService.trackUser().subscribe();

    this.commonSrv.GetMenu().subscribe((resp) => {
      this.menu = resp;

      if (this.menu.Style != "") {
        this.loadStyle(this.menu.Style);
      }

      setTimeout(function () {
        that.reloadData();
      }, 500);
    }, (err) => {
    });
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `assets/css/Brands/${styleName}`;

      head.appendChild(style);
    }
  }

  closeMenu() {
    if ($(window).width() <= (1024) && $("body").hasClass("sidebar-open")) {
      $("body").removeClass('sidebar-open');
    }
  }

  reloadData() {
    const that = this;
    $(".page").on('click', function () {
      that.closeMenu();
    });

    $(".sidebar").slimscroll({      
      height: ($(window).height()) + "px"
    });

  }

  logout() {
    Swal.fire({
      title: '¿Quieres salir de la aplicación?',
      text: "Se eliminarán tus datos de sesión y saldrás de la aplicación",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Salir',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      focusCancel: true
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        window.location.href = this.grpUrl;
      }
    });
  }

}
