import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { SearchModel } from '../models/search';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchTerm(term: string, fromItem: number, toItem: number, termTypeCode: string): Observable<SearchModel> {
    return this.http.get<SearchModel>(this.pathAPI + 'Shared/Search/' + term + '?fromItem=' + fromItem + '&totItem=' + toItem + '&termTypeCode=' + termTypeCode);
  }
}
