<!--Preloader-->

<div class="d-flex align-items-center">
    <div *ngIf="showEasymarketing" class="navbar__title cursor-pointer" (click)="gotoEasymarketing()">EasyMarketing</div>
    <a class="nav-link" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <div class="navbar__icon-search"></div>
    </a>
    <div class="dropdown custom-dropdown notifications-menu cursor-pointer">
        <a class="nav-link sep__icon-header" (click)="getCountNotification()" data-toggle="control-sidebar">
            <div class="navbar__icon-bell"></div>
            <span *ngIf="this.countNotification > 0" class="badge badge-danger badge-mini rounded-circle">{{this.countNotification}}</span>
        </a>
    </div>
    <div class="dropdown custom-dropdown notifications-menu cursor-pointer">
        <a class="nav-link" (click)="showTabDownloads = true" data-toggle="control-sidebar-downloads">
            <div class="navbar__icon-download"></div>
            <span *ngIf="this.countDownloadsCurrent  > 0" class="badge badge-danger badge-mini rounded-circle"> {{ this.countDownloadsCurrent }}</span>
        </a>
    </div>
</div>
<aside class="control-sidebar fixed white bg-grey border-color-grey">
    <div class="slimScroll">
        <div class="sidebar-header d-flex align-items-center">
            <div class="navbar__icon-bell mr-2"></div>
            <h4 class="subheading font-family-bold text-color-dark mt-1">Notificaciones</h4>
            <a href="#" data-toggle="control-sidebar" class="paper-nav-toggle active"><i></i></a>
        </div>
        <div class="table-responsive">
            <div class="text-center" *ngIf="this.loading">
                <div class="preloader-wrapper small active">
                    <div class="spinner-layer spinner-green-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            <dl class="dl-horizontal mb-3">
                <ng-container *ngFor="let item of this.data">
                    <div class="sidebar-header__content">
                        <div class="sidebar-header__bell">
                            <div class="sidebar-header__bell--small">{{item.PublishSince}} <i (click)="closeNotification(item.IdNotification)" class="sidebar-header__icon"><i class="icon-leave"></i></i>
                            </div>
                        </div>
                        <ng-container *ngIf="item.Url != null">
                            <a (click)="goToNotificationLink(item)" style="cursor:pointer">
                                <h5>{{item.Title}}</h5>
                                <div class="sidebar-header__bell--text">{{item.Content}}</div>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="item.Url == null">
                            <h5>{{item.Title}}</h5>
                            <div class="sidebar-header__bell--text">{{item.Content}}</div>
                        </ng-container>
                    </div>
                </ng-container>
            </dl>
            <div class="icon-plus-notification" *ngIf="this.showMore">
                <a (click)="loadMore()">
                    <i class="icon-plus"></i></a>
            </div>
            <!-- <div class="icon-plus-notification" *ngIf="this.showDownloads">
                <a (click)="this.showDownloads = true">
                    <i class="icon-plus"></i></a>
            </div> -->
        </div>
    </div>
</aside>


<aside class="control-sidebar-downloads fixed white bg-grey border-color-grey" *ngIf="showTabDownloads">
    <div class="slimScroll">
        <div class="sidebar-header d-flex align-items-center">
            <div class="not__icon-download mr-2"></div>
            <h4 class="subheading font-family-bold text-color-dark mt-1">Descargas en proceso {{ this.countDownloadsCurrent > 0 ? "(" + this.countDownloadsCurrent + ")":"" }} </h4>
            <a (click)="showTabDownloads = false" class="paper-nav-toggle active"><i></i></a>
        </div>
        <div class="table-responsive">
            <dl class="dl-horizontal mb-3">
                <ng-container *ngFor="let item of this.listDownloads">
                    <div class="sidebar-header__content" *ngIf="item.Download">
                        <div>
                            {{item.Title}}
                        </div>
                        <div>
                            <span style="font-size: 10px;"> <strong>{{item.File_Name}}</strong></span>
                        </div>
                        <div>
                            <ngb-progressbar type="secondary" [value]="item.Percentage" [striped]="true"></ngb-progressbar>
                        </div>
                    </div>
                </ng-container>
            </dl>
        </div>
    </div>
</aside>