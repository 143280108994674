import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../models/user';
import { Globals } from '../../globals';
import { DOCUMENT } from '@angular/common';
import { BrandStringIdPipe } from 'src/app/pipes/brandstringid.pipe';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private idBrand: number;
  private brandStringId: string;

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  private userData = new BehaviorSubject<UserModel>(null);

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private globals: Globals,
    @Inject(DOCUMENT) private document: Document
    ) {
    this.idBrand = globals.idBrand;
    this.initializeUserData();
  }

  public getUserInfo(): Observable<UserModel> {
    return this.http.get<UserModel>(this.pathAPI + 'VwUser/GetUserInfo');
  }

  public trackUser(): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwUser/TrackUser', null);
  }

  //-------------------------------

  setUserData(user: UserModel) {
    this.userData.next(user);
  }

  getUserData(): Observable<UserModel> {
    return this.userData.asObservable();
  }

  public initializeUserData() {
    const that = this;
    this.getUserInfo().subscribe(resp => {
      that.setUserData(resp);
      this.globals.idBrand = resp.Brand.IdBrand;
      let directoryBrandFilter = new BrandStringIdPipe();
      this.brandStringId = directoryBrandFilter.transform(this.globals.idBrand);
      this.document.body.classList.add(this.brandStringId);
    });
    
  }

}
