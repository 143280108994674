import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/config/config';
import { ChangeOrderModel, DataTablesResponse, TreeViewModel } from 'src/app/models/common';
import { ImageBankCategoryModel, ImageBankHomeModel, ImageBankMultimediaHomeModel, ImageBankMultimediaModel, ImageBankOrganizationModel } from '../models/imagebank';


@Injectable({
  providedIn: 'root'
})

export class ImageBankService {
  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public getDataHome(): Observable<ImageBankHomeModel[]> {
    return this.http.get<ImageBankHomeModel[]>(this.pathAPI + 'VwGallery/GetVwGalleryHome');
  }

  public getDataMultimediaHome(id: string): Observable<ImageBankMultimediaHomeModel[]> {
    return this.http.get<ImageBankMultimediaHomeModel[]>(this.pathAPI + 'VwGallery/GetVwGalleryLst/' + id);
  }

  public getDataVideoHome(): Observable<ImageBankMultimediaHomeModel[]> {
    return this.http.get<ImageBankMultimediaHomeModel[]>(this.pathAPI + 'VwGallery/GetVwGalleryVideoLst');
  }

  public getDataModelHome(id: string): Observable<ImageBankHomeModel> {
    return this.http.get<ImageBankHomeModel>(this.pathAPI + 'VwGallery/GetVwGalleryVersion/' + id);
  }

  public searchData(data: any, idOrganizationParent: string): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwGallery/GetDataTableOrganization/' + idOrganizationParent, data);
  }

  public searchDataCategory(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwGallery/GetDataTableCategory', data);
  }

  public searchDataMultimedia(data: any, idModel: string, idVersion: string): Observable<DataTablesResponse> {
    let model = "0";
    let version = "0";

    if(idModel !== null) {
      model = idModel;
    }

    if(idVersion !== null) {
      version = idVersion;
    }

    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwGallery/GetDataTable/' + model + "/" + version, data);
  }

  public get(id: string): Observable<ImageBankOrganizationModel> {
    return this.http.get<ImageBankOrganizationModel>(this.pathAPI + 'VwGallery/GetOrganization/' + id);
  }

  public getMultimedia(id: string): Observable<ImageBankMultimediaModel> {
    return this.http.get<ImageBankMultimediaModel>(this.pathAPI + 'VwGallery/' + id);
  }

  public getCategory(id: string): Observable<ImageBankCategoryModel> {
    return this.http.get<ImageBankCategoryModel>(this.pathAPI + 'VwGallery/GetCategory/' + id);
  }

  public post(id:string, data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwGallery/CreateOrganization/' + id, data);
  }

  public postMultimedia(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwGallery/', data);
  }

  public postCategory(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwGallery/CreateCategory', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwGallery/UpdateOrganization/' + id, data);
  }

  public putMultimedia(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwGallery/' + id, data);
  }

  public putCategory(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwGallery/UpdateCategory/' + id, data);
  }

  public getTreeViewHierarchy(): Observable<TreeViewModel[]> {
    return this.http.get<TreeViewModel[]>(this.pathAPI + 'VwGallery/GetTreeViewHierarchy');
  }

  public saveFiles(fileImage: File): Observable<any> {
    let formData: FormData = new FormData();

    if (fileImage != undefined) {
      formData.append('fileImage', fileImage, fileImage.name);
    }
    return this.http.post<any>(this.pathAPI + 'VwGallery/SaveOrganizationFiles', formData);
  }

  public saveFilesMultimedia(fileMultimedia: File, fileImage: File): Observable<any> {
    let formData: FormData = new FormData();

    if (fileImage != undefined) {
      formData.append('fileImage', fileImage, fileImage.name);
    }
    if (fileMultimedia != undefined) {
      formData.append('fileMultimedia', fileMultimedia, fileMultimedia.name);
    }
    return this.http.post<any>(this.pathAPI + 'VwGallery/SaveFiles', formData);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwGallery/ChangeStatusOrganization/' + id + '/' + idStatus, null);
  }

  public changeStatusCategory(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwGallery/ChangeStatusCategory/' + id + '/' + idStatus, null);
  }

  public changeStatusMultimedia(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwGallery/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwGallery/ChangeOrder', data);
  }

  public changeOrderMultimedia(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwGallery/ChangeOrderGallery', data);
  }

  public trackGallery(id: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwGallery/TrackGallery/' + id, null);
  }

  public getGalleryFile(id: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwGallery/GetGalleryFile/' + id;

    return this.http.get<Blob>(endpoint, requestOptions);
  }

}
