<!--Modal header-->
<div class="modal-header">
    <h5 class="modal-title">Exclusiones</h5>
    <button type="button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    </button>
</div>
<!--Modal body-->
<div class="modal-body modal-scroll">
    <p>
        Audi tiene una serie de lineamientos que debemos de respetar, estas son las <strong>exclusiones</strong> que la marca tiene para realizar acciones de Marketing (acciones, alianzas y patrocinios).
    </p>
    <ul class="slash__list">
        <li>
            Artes marciales, tiro, esgrima, levantamiento de pesas (incluido boxeo, lucha libre, artes marciales mixtas, deportes de combate, artes marciales asiáticas).
        </li>
        <li>
            Deportes sobre hielo (incluido hockey sobre hielo, patinaje artístico y de velocidad, curling).
        </li>
        <li>
            Trineo (incl. Luge, Skeleton, Sled Dog Racing).
        </li>
        <li>
            Deportes de raqueta (incl. tenis, bádminton, tenis de mesa, squash).
        </li>
        <li>
            Voleibol, Voleibol Playa, Balonmano, Gimnasia.
        </li>
        <li>
            Atletismo (incl. Atletismo, Carreras), Triatlón está restringido a eventos locales (patrocinios de ubicación).
        </li>
        <li>
            Ciclismo de ruta y pista (excepciones: ciclismo de montaña, BMX).
        </li>
        <li>
            Equitación, Carreras de Caballos (incl. Polo).
        </li>
        <li>
            Natación (incl. Buceo, Waterpolo).
        </li>
        <li>
            Deportes aéreos (incluidos paracaidismo, vuelo sin motor, carreras aéreas).
        </li>
        <li>
            Torneos de golf profesional (incluidos eventos de la PGA, Ryder Cup).
        </li>
        <li>
            Vela, Piragüismo, Kayak.
        </li>
        <li>
            Carreras de motos (excepto equipos oficiales Ducati).
        </li>
    </ul>
    <p>
        Audi no realiza acuerdos de patrocinio ni ningún otro tipo de cooperación con marcas o acciones/plataformas dominados o propiedad de marcas en las siguientes categorías:
    </p>
    <ul class="slash__list">
        <li>
            Bebidas alcohólicas (incl. cerveza).
        </li>
        <li>
            Tabaco.
        </li>
        <li>
            Armas.
        </li>
        <li>
            Apuestas (Casinos).
        </li>
        <li>
            Pornografía.
        </li>
        <li>
            Cualquier otra marca o categoría de marca que pueda implicar un riesgo de reputación para Audi.
        </li>
        <li>
            Pádel: exclusivo marca Cupra.
        </li>
        <li>
            Hockey: exclusivo marca Skoda.
        </li>
        <li>
            Ciclismo: exclusivo marca Skoda.
        </li>
    </ul>
</div>