<div class="d-flex height-48">
    <input type="text" autocomplete="off" name="seachDocument" id="seachDocument" class="input-search transparent s-18 b-0" (keyup)="searchByKey($event)" placeholder="Buscar..." />
    <a (click)="searchByClick()" class="btn-search"><i  class="btn-search__icon-search"></i></a>
</div>



<!--Modal-->
<ng-template #modalSearchDocument let-modal>
    <div class="modal-search">
        <div class="modal-header">
            <span class="modal__icon-search mr-2"></span>
            <h4 class="modal-title">
                Resultado de búsqueda: <strong>{{ searchText }}</strong>
            </h4>
            <a class="d-flex" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="icon-leave"></i></a>
        </div>
        <div class="modal-body">
            <div class="d-flex border-bottom-black">
                <button [ngClass]="termTypeCode == 'document' ? 'btn btn-secondary mr-2' : 'btn btn-invert mr-2'" (click)="filterTermTypeCode('document')">Documentos</button>
                <button [ngClass]="termTypeCode == 'fichatecnica' ? 'btn btn-secondary mr-2' : 'btn btn-invert mr-2'" (click)="filterTermTypeCode('fichatecnica')">Ficha técnica</button>
                <button [ngClass]="termTypeCode == 'multimedia' ? 'btn btn-secondary mr-2' : 'btn btn-invert mr-2'" (click)="filterTermTypeCode('multimedia')">Banco de imágenes</button>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div [hidden]="!noResult" class="mt-4">No se han encontrado resultados</div>
                    <div [hidden]="!loading" class="preloader-wrapper small active mt-4">
                        <div class="spinner-layer spinner-blue">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                    <table [hidden]="loading" class="table">
                        <tbody>
                            <tr *ngFor="let item of searchResult.items">
                                <ng-template [ngIf]="item.TermTypeCode == 'document'">
                                    <td>
                                        <div class="category">Documento</div>
                                    </td>
                                    <td><i class="icon {{ item.Icon }} text-{{ item.IconColor }}"></i></td>
                                    <td class="p-0" colspan="2">
                                        <table class="table table-rowspan">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex justify-content-between mt-1">
                                                            <div class="search-item-title">
                                                                {{ item.Name }}
                                                            </div>
                                                            <div>
                                                                {{ item.PublishDate | date: 'dd/MM/yyyy' }}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="w-8 text-center">
                                                        <a class="download__icon-w action__icon mr-2" title="Descargar" (click)="downloadDocument(item.Id, item.fileName)"></a>
                                                        <!-- <button class="btn btn-primary" (click)="downloadDocument(item.Id, item.Name)"> <i class="ico icon-download"></i></button> -->
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let section of item.Sections">
                                                    <td>
                                                        <span class="location mt-1">Ubicación</span>: {{ section.Name }}
                                                    </td>
                                                    <td class="w-8 text-center">
                                                        <a class="preview__icon-w action__icon mr-2" title="Previsualizar" [href]="linkSection(section.Id)" target="_blank"></a>
                                                        <!-- <a [href]="linkSection(section.Id)" class="btn btn-primary"><i class="ico icon-eye"></i></a> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </ng-template>
                                <ng-template [ngIf]="item.TermTypeCode == 'fichatecnica'">
                                    <td>
                                        <div class="category">Ficha técnica</div>
                                    </td>
                                    <td><i class="icon {{ item.Icon }} text-{{ item.IconColor }}"></i></td>
                                    <td>
                                        <div class="search-item-title mt-1">{{ item.Name }}</div>
                                    </td>
                                    <td class="w-8 text-center">
                                        <a class="download__icon-w action__icon mr-2" title="Descargar" (click)="downloadFichaTecnica(item.Id, item.fileName)" style="margin-left: 10px;"></a>
                                    </td>
                                </ng-template>
                                <ng-template [ngIf]="item.TermTypeCode == 'multimedia'">
                                  <td>
                                      <div class="category">Banco de imágenes</div>
                                  </td>
                                  <td>
                                      <div class="search-item-title mt-1">{{ item.Name }}</div>
                                  </td>
                                  <td class="w-8 text-center">
                                      <a class="download__icon-w action__icon mr-2" title="Descargar" (click)="downloadMultimedia(item.Id, item.fileName)" style="margin-left: 10px;"></a>
                                  </td>
                              </ng-template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="box-tools">
                <ul class="pagination pagination-sm no-margin float-right">
                    <li class="page-item"><a [ngClass]="currentPage > 0 ? 'page-link' : 'page-link disabled'" (click)="pagingSearch(this.currentPage - 1)">Anterior</a></li>
                    <li [ngClass]="currentPage == i ? 'page-item active' : 'page-item'" *ngFor='let key of pages; let i = index' [attr.data-index]="i">
                        <a class="page-link" (click)="pagingSearch(i)">{{ i + 1 }}</a>
                    </li>
                    <li class="page-item"><a [ngClass]="currentPage + 1 < pages.length ? 'page-link' : 'page-link disabled'" (click)="pagingSearch(this.currentPage + 1)">Siguiente</a></li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
