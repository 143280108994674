import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { NgSelect2Module } from 'ng-select2';
import { SwiperModule } from "swiper/angular";
import * as $ from 'jquery';
import { Globals } from './globals';
registerLocaleData(es);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './config/config';

import { HtmlSeguroPipe } from './pipes/htmlseguro.pipe';
import { Helpers } from './helpers/helpers';

import { ComunModule } from './comun.module';

import { AuthInterceptorService } from './services/auth-interceptor.service';
import { CallbackComponent } from './components/callback/callback.component';

import { TooltipModule } from 'ng2-tooltip-directive';

import { InfogroupLayoutComponent, EasymarketingLayoutComponent  } from './containers';
import { InfogroupModule } from './Infogroup-app/infogroup.module';
import { EasymarketingModule } from './easymarketing-app/easymarketing.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const APP_CONTAINERS = [
  InfogroupLayoutComponent,
  EasymarketingLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    HtmlSeguroPipe,
    ...APP_CONTAINERS
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ComunModule,
    NgSelect2Module,
    TooltipModule,
    SwiperModule,
    ToastrModule.forRoot(),
    InfogroupModule,
    EasymarketingModule
  ],
  exports: [
    HtmlSeguroPipe
  ],
  providers: [
    AppConfig,
    Helpers,
    NgbActiveModal,
    Globals,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
