import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../../config/config';
import { DataTablesResponse } from '../../models/common';
import { VwDocumentDTO } from '../models/document';
import { LastUpdatesModelDTO } from '../models/lastupdates';
import { Select2OptionData } from 'ng-select2';
import { TreeViewModel } from '../models/tree-view';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {
  listDownloads = new Subject<LastUpdatesModelDTO>();
  changeOpenTabViewDownload = new Subject<boolean>();


  private pathAPI = this.config.setting['PathAPIInfogroup'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any, idBrand: number, idMenu: number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwDocument/GetDataTable/' + idBrand + '/' + idMenu, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwDocument/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public get(id: string): Observable<VwDocumentDTO> {
    return this.http.get<VwDocumentDTO>(this.pathAPI + 'VwDocument/' + id);
  }

  public getDocumentParent(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'VwDocument/GetVwDocumentParent');
  }

  public saveDocumentFiles(fileImage: File): Observable<any> {
    let formData: FormData = new FormData();
    if (fileImage != undefined) {
      formData.append('fileImage', fileImage, fileImage.name);
    }
    return this.http.post<any>(this.pathAPI + 'VwDocument/SaveDocumentFiles', formData);
  }

  public getDocumentFile(idDocument: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwDocument/GetDocumentFile/' + idDocument;

    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public getDocumentFileProgress(idDocument: string):  Observable<HttpEvent<any>>{
    let endpoint = this.pathAPI + 'VwDocument/GetDocumentFileProgressBar/' + idDocument;
    return this.http.post(endpoint , null , {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders(
        { 'Content-Type': 'application/json' },
      )
    });
  }

  public getDocumentFileAsync(idDocument: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwDocument/GetDocumentAsync/' + idDocument;

    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwDocument', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwDocument/' + id, data);
  }

  public GetVwDocumentLst(): Observable<LastUpdatesModelDTO[]> {
    return this.http.get<LastUpdatesModelDTO[]>(this.pathAPI + 'VwDocument/GetVwDocumentLst');
  }

  public getTreeViewMenu(): Observable<TreeViewModel[]> {
    return this.http.get<TreeViewModel[]>(this.pathAPI + 'VwDocument/GetTreeViewMenu');
  }

  public trackDocument(idDocument: string, idLogType:number): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwDocument/TrackDocument/' + idDocument + '/' + idLogType, null);
  }

}
