import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse, TreeViewModel } from '../../models/common';
import { DocumentModel, DocumentOrganizationModel } from '../models/document';
import { Select2OptionData } from 'ng-select2';
import { LastUpdatesModelDTO } from '../models/lastupdates';
import { MostViewedModelDTO } from '../models/mostviewed';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {
  listDownloads = new Subject<LastUpdatesModelDTO>();
  changeOpenTabViewDownload = new Subject<boolean>();

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public get(id: string): Observable<DocumentModel> {
    return this.http.get<DocumentModel>(this.pathAPI + 'VwDocument/' + id);
  }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwDocument/GetDataTable', data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwDocument/ChangeOrder', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwDocument/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public getOrganizationChildren(id: string): Observable<DocumentOrganizationModel[]> {
    if (id === undefined || id === null) {
      return this.http.get<DocumentOrganizationModel[]>(this.pathAPI + 'VwDocument/GetOrganizationChildren');
    }
    else {
      return this.http.get<DocumentOrganizationModel[]>(this.pathAPI + 'VwDocument/GetOrganizationChildren/' + id);
    }
  }

  public createOrganization(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwDocument/CreateOrganization', data);
  }

  public updateOrganization(id: number, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwDocument/UpdateOrganization/' + id, data);
  }

  public getOrganization(id: number): Observable<DocumentOrganizationModel> {
    return this.http.get<DocumentOrganizationModel>(this.pathAPI + 'VwDocument/GetOrganization/' + id);
  }

  public getStatisticsTabs(idBrand: number): Observable<any> {    
    return this.http.get<DocumentOrganizationModel>(this.pathAPI + 'VwDocument/GetStatisticsTabs/' + idBrand);
  }

  public changeOrganizationOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwDocument/ChangeOrder', data);
  }

  public getOrganizationDesc(id: string): Observable<any> {
    let options = { responseType: 'text' as 'text' };
    return this.http.get(this.pathAPI + 'VwDocument/GetOrganizationDesc/' + id, options);
  }

  public deleteOrganization(idOrganization: string): Observable<any> {
    return this.http.delete<any>(this.pathAPI + 'VwDocument/DeleteOrganization/' + idOrganization);
  }

  public getOrganizationParent(id?: string): Observable<any> {
    if (id !== undefined && id !== null) {
      return this.http.get<any>(this.pathAPI + 'VwDocument/GetOrganizationParent/' + id);
    }
    return null;
  }

  public getDocumentFile(idDocument: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwDocument/GetDocumentFile/' + idDocument;

    return this.http.get<Blob>(endpoint, requestOptions);
  }


  public getDocumentFileProgress(idDocument: string):  Observable<HttpEvent<any>>{
    let endpoint = this.pathAPI + 'VwDocument/GetDocumentFileProgressBar/' + idDocument;
    return this.http.post(endpoint , null , {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders(
        { 'Content-Type': 'application/json' },
      )
    });
  }




  public saveDocumentFiles(fileDocument: File, fileImage: File): Observable<any> {
    let formData: FormData = new FormData();

    if (fileDocument != undefined) {
      formData.append('fileDocument', fileDocument, fileDocument.name);
    }

    if (fileImage != undefined) {
      formData.append('fileImage', fileImage, fileImage.name);
    }
    return this.http.post<any>(this.pathAPI + 'VwDocument/SaveDocumentFiles', formData);
  }

  public getDocumentParent(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'VwDocument/GetVwDocumentParent/');
  }

  public GetVwDocumentByOrganization(id:string): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'VwDocument/GetVwDocumentByDocumentOrganization/' + id);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwDocument', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwDocument/' + id, data);
  }

  public getTreeViewHierarchy(): Observable<TreeViewModel[]> {
    return this.http.get<TreeViewModel[]>(this.pathAPI + 'VwDocument/GetTreeViewHierarchy');
  }

  public getTreeViewHierarchyOrder(id: number): Observable<TreeViewModel[]> {
    return this.http.get<TreeViewModel[]>(this.pathAPI + 'VwDocument/GetTreeViewHierarchyOrder/' + id);
  }

  /*VISTA CONCESIONARIO*/

  public GetDocumentYear(id: string): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'VwDocument/GetVwDocumentClientYear/' + id);
  }

  public GetDocumentOrganization(id: string): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'VwDocument/GetVwDocumentClientOrganization/' + id);
  }

  public searchDataClient(data: any, idOrganization: string, year: number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwDocument/GetDataTableClient/' + idOrganization + '/' + year, data);
  }

  public GetDocumentOrganizationData(id: string): Observable<DocumentOrganizationModel> {
    return this.http.get<DocumentOrganizationModel>(this.pathAPI + 'VwDocument/GetVwDocumentOrganizationData/' + id);
  }


  public GetVwDocumentLst(): Observable<LastUpdatesModelDTO[]> {
    return this.http.get<LastUpdatesModelDTO[]>(this.pathAPI + 'VwDocument/GetVwDocumentLst');
  }

  public trackDocument(idDocument: string, idLogType:number): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwDocument/TrackDocument/' + idDocument + '/' + idLogType, null);
  }

  public getSectionMostViewed(): Observable<MostViewedModelDTO[]> {
    return this.http.get<MostViewedModelDTO[]>(this.pathAPI + 'VwDocument/GetMostViewed');
  }

  public searchDataClientMaterial(idOrganization: string, year: number): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwDocument/GetDataClientMaterial/' + idOrganization + '/' + year, null);
  }
}
