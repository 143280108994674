import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserModel } from '../models/user';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  userInfo: UserModel;

  constructor(private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {
    this.userService.getUserInfo().subscribe(userInfo => this.userInfo = userInfo);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.isLoggedIn()) {

      if (this.userInfo != undefined) {

        if (route.data.roles) {
          let rolValid = false;

          if (route.data.roles.indexOf(this.userInfo.Rol.Rol) !== -1) {
            rolValid = true;
          }

          if (rolValid === false) {
            this.router.navigate(['/home']);
            return false;

          }

        }
      }
      return true;
    }
    this.authService.startAuthentication();
    return false;
  }
}
