<footer *ngIf="this.user != undefined && this.user.Brand.IdBrand == 1">
    <div class="footer__wrapper bg-secondary">
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <div class="footer__text text-uppercase footer__text-year">&copy; Volkswagen Group 2023</div>
            </div>
            <div class="col-md-9 col-xs-12 text-right">
                <div class=" footer__text">Atención a la Red: 222 303 5911 / Horarios: de Lunes a viernes de 9h a 18:00h / sistemas.servicio@vw.com.mx</div>
            </div>
        </div>
    </div>
</footer>

<footer *ngIf="this.user != undefined && this.user.Brand.IdBrand == 2">
    <div class="footer__wrapper bg-secondary">
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <div class="footer__text text-uppercase footer__text-year">&copy; Volkswagen Group 2023</div>
            </div>
            <div class="col-md-9 col-xs-12 text-right">
                <div class=" footer__text">Atención a la Red / Horarios: de lunes a viernes de 9h a 18:00h / nancy.gonzalez@audi.com.mx
                </div>
            </div>
        </div>
    </div>
</footer>

<footer *ngIf="this.user != undefined && this.user.Brand.IdBrand == 3">
    <div class="footer__wrapper bg-secondary">
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <div class="footer__text text-uppercase footer__text-year">&copy; Volkswagen Group 2023</div>
            </div>
            <div class="col-md-9 col-xs-12 text-right">
                <div class=" footer__text">Atención a la Red: 222 303 5911 / Horarios: de Lunes a viernes de 9h a 18:00h / sistemas.servicio@vw.com.mx</div>
            </div>
        </div>
    </div>
</footer>

<footer *ngIf="this.user != undefined && this.user.Brand.IdBrand == 4">
    <div class="footer__wrapper bg-secondary">
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <div class="footer__text text-uppercase footer__text-year">&copy; Volkswagen Group 2023</div>
            </div>
            <div class="col-md-9 col-xs-12 text-right">
                <div class=" footer__text">Atención a la Red: 222 303 5911 / Horarios: de Lunes a viernes de 9h a 18:00h / sistemas.servicio@vw.com.mx</div>
            </div>
        </div>
    </div>
</footer>


<!--
<footer>
    <div class="footer__wrapper bg-secondary">
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <div class="footer__text text-uppercase footer__text-year">&copy; Volkswagen Group 2021</div>
            </div>
            <div class="col-md-9 col-xs-12 text-right">
                <div class=" footer__text">Atención a la Red: 222 303 5911 / Horarios: de Lunes a viernes de 9h a 18:00h / sistemas.servicio@vw.com.mx</div>
            </div>
        </div>
    </div>
</footer>-->