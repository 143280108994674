import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'addtoken'
})
export class AddTokenPipe implements PipeTransform {

  token: string;

  constructor(private authService: AuthService) {    
  }

  async transform(value: string): Promise<any> {
    this.token = await this.authService.getToken();
    return value + '?access_token=' + this.token;
  }

}
