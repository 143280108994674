import { Component, Input } from '@angular/core';

@Component({
  selector: '[menu-emseat]',
  templateUrl: './menu.component.html'
})
export class MenuComponent {

  @Input() items!: any[];

  constructor() {
  }
}
