<div class="container__logo">
    <div class="logo__ig">
        <img src="../../../assets/img/Infogroup.svg" alt="Infogroup">
    </div>
    <div class="loading">
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>