import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../../models/user';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  user: UserModel;

 constructor(private authService: UserService) { }

  ngOnInit(): void {
    this.authService.getUserInfo().subscribe(resp => {
      this.user = resp;
    });
  }

}

