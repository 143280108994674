import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../models/user';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private userData = new BehaviorSubject<UserModel>(null);

  private pathAPI = this.config.setting['PathAPIInfogroup'];

  constructor(private http: HttpClient, private config: AppConfig) {    
  }

  public getUserInfo(): Observable<UserModel> {
    return this.http.get<UserModel>(this.pathAPI + 'VwUser/GetUserInfo');
  }

  public trackUser(): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwUser/TrackUser', null);
  }

  setUserData(identy: UserModel) {
    this.userData.next(identy);
  }

  getUserData(): Observable<UserModel> {
    return this.userData.asObservable();
  }

}
