import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AppConfig {
  private _config: { [key: string]: string };

  constructor() {
    this._config = {      
      Authority: environment.authority,
      ClientId: environment.clientId,
      ClientSecret: environment.clientSecret,
      RetailUrl: environment.retialUrl,
      grpUrl: environment.grpUrl,
      PathWeb: environment.urlWeb,
      PathPublicBlob: environment.publicBlobUrl,

      PathAPIInfogroup: environment.urlAPIInfogroup,
      PathServerInfogroup: environment.urlServerInfogroup,
      PathAPIEMSeat: environment.urlAPIEMSeat,
      PathServerEMSeat: environment.urlServerEMSeat
      

    };
  }

  get setting(): { [key: string]: string } {
    return this._config;
  }

  get(key: any) {
    return this._config[key];
  }
};
