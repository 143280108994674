export const environment = {
  production: true,  
  urlWeb: 'https://www.infogroup.com.mx/',
  authority: 'https://grp.volkswagenag.com/',
  clientId: 'infogroup_411',
  clientSecret: 'XdwXNL5rBfAGpuCTN2L5hQuP',  
  retialUrl: 'https://grp.global.volkswagenag.com/Retail/infogroup/start',
  grpUrl: 'https://grp.volkswagenag.com/',
  publicBlobUrl: 'https://blobinfogorup.blob.core.windows.net/images/',

  urlAPIInfogroup: 'https://api-infogroup.azurewebsites.net/api/',
  urlServerInfogroup: 'https://api-infogroup.azurewebsites.net/',
  urlAPIEMSeat: 'https://api-easymarketingseat.azurewebsites.net/api/',
  urlServerEMSeat: 'https://api-easymarketingseat.azurewebsites.net/'
};
