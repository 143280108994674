import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[menu-infogroup]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  @Input() items: any[];

  constructor() {
  }
}
