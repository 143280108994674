import { Injectable } from '@angular/core';

@Injectable()
export class Helpers {

  public loadInitializeFormScript() {
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("initialize.form-float")) {
        scripts[i].remove();
      }
    }

    var dynamicScripts = [window.location.origin + "/assets/js/initialize.form-float.js"];

    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  public isValidEmail(email: string): boolean {
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regex.test(email) === true && email.trim().length > 0) {
      return true;
    }
    return false;
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>theBlob;
  }

  public getDateString(yourDate: Date): string {
    yourDate = new Date(yourDate);
    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
    return yourDate.toISOString().split('T')[0]
  }

  public getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    var m = `${today.getMonth() + 1}`.padStart(2, "0")
    var d = `${today.getDate() + 1}`.padStart(2, "0")
    var h = `${today.getHours() + 1}`.padStart(2, "0")
    var mi = `${today.getMinutes() + 1}`.padStart(2, "0")
    var s = `${today.getSeconds() + 1}`.padStart(2, "0")
    return y + m + d + h + mi + s;
  }

}
