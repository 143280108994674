import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './components/callback/callback.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { EasymarketingLayoutComponent, InfogroupLayoutComponent } from './containers';
import { easymarketingroutes } from './easymarketing-app/easymarketing-routing.module';
import { infogroupRoutes } from './Infogroup-app/infogroup-routing.module';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },  
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: '',
    component: InfogroupLayoutComponent,
    children: infogroupRoutes
  },
  {
    path: 'easymarketing',
    component: EasymarketingLayoutComponent,
    children: easymarketingroutes
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

