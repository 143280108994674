import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'htmlseguro', pure: false })
export class HtmlSeguroPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content) {

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
