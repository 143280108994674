import { NumberSymbol } from "@angular/common";

export class SearchModel {
    total_item: number;
    items: SearchItemModel[];
}

export class SearchItemModel {
    Icon: string;
    IconColor: string;
    Id: string;
    Kb: number;
    Name: string;
    Url: string;
    PublishDate: Date;
    TermTypeCode: string;
    Terms: string;
}

