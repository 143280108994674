import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'brandstringid'
})
export class BrandStringIdPipe implements PipeTransform {

  constructor() { }

  transform(value: number): any {
    let directory: string;
    if (value == 1) {
        directory = 'seat';
      } else if (value == 2) {
        directory = 'audi';
      }
    return directory;
  }

}
