import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComunModule } from '../comun.module';

import { MenuComponent } from './components/shared/menu/menu.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { SearchComponent } from './components/shared/search/search.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalEventExclusionsAudiComponent } from './components/shared/modal-event-exclusions-audi/modal-event-exclusions-audi.component';

@NgModule({
  declarations: [
    MenuComponent,
    HeaderComponent,
    SearchComponent,
    ModalEventExclusionsAudiComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ComunModule,
    BrowserAnimationsModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    MenuComponent,
    HeaderComponent,
    SearchComponent,
  ]
})
export class EasymarketingModule { }
