import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-event-exclusions-audi',
  templateUrl: './modal-event-exclusions-audi.component.html',
  styleUrls: ['./modal-event-exclusions-audi.component.scss']
})

export class ModalEventExclusionsAudiComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  showExclusions() {
    $('.modal-scroll').animate({ scrollTop: 0 }, "slow");
  }

  closeModal() {
    this.activeModal.close();
  }

}
