import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



//Pipes
import { DomseguroPipe } from './pipes/domseguro.pipe';
import { AddTokenPipe } from './pipes/addtoken.pipe';
import { BrandStringIdPipe } from './pipes/brandstringid.pipe';
import { RunScriptsDirective } from './directives/runScript.directive';
import { dateFormatPipe } from './pipes/dateFormat.pipe';

@NgModule({
  declarations: [
    DomseguroPipe,
    AddTokenPipe,
    BrandStringIdPipe,
    RunScriptsDirective,
    dateFormatPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    DomseguroPipe,
    AddTokenPipe,
    BrandStringIdPipe,
    RunScriptsDirective,
    dateFormatPipe
  ]
})
export class ComunModule { }
