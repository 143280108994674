import { Injectable } from "@angular/core";
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { from, Observable, ReplaySubject } from "rxjs";
import { AppConfig } from "../config/config";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  config: UserManagerSettings = {
    authority: this.appconfig.setting['Authority'],
    client_id: this.appconfig.setting['ClientId'],
    client_secret: this.appconfig.setting['ClientSecret'],
    redirect_uri: `${this.appconfig.setting['PathWeb']}callback`,
    response_type: "code",
    scope: "openid profile email accountip accountdp",
    post_logout_redirect_uri: `${this.appconfig.setting['PathWeb']}`,
    loadUserInfo: false
  };

  private mgr: UserManager;
  private pUser = new ReplaySubject<User>(1);
  user$: Observable<User> = this.pUser.asObservable();
  private user: User;  
  
  constructor(private appconfig: AppConfig) {
    this.mgr = new UserManager(this.config);
  }

  /* Nueva implementación */
  isLoggedIn(): boolean {    
    if (localStorage['user_infogroup'] != null || localStorage['user_infogroup'] != undefined) {      
      let user = JSON.parse(localStorage['user_infogroup']);
      if (user != null && user != undefined && !user.expired) {
        this.pUser.next(user);
        this.user = user;
        return true;
      }
    }    
    return false;

  }

  getClaims(): any {
    return this.user.profile;
  }

  getUser() {
    return this.user;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.id_token}`;
  }

  getToken(): string {
    return this.user.id_token;
  }

  startAuthentication(): Promise<void> {    
    return this.mgr.signinRedirect();
  }

  completeAuthentication(): Promise<void> {    
    return this.mgr.signinRedirectCallback().then(user => {
      this.user = user;
      localStorage['user_infogroup'] = JSON.stringify(user);

      window.location.href = this.appconfig.setting['RetailUrl'];
    });
  }

}
