import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  constructor(
    private router: Router,
    private auth: AuthService   
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    let request = req;
    if (!this.auth.isLoggedIn()) {
      this.auth.startAuthentication();
    } else {
      request = req.clone({
          setHeaders: {
            authorization: this.auth.getAuthorizationHeaderValue(),
            target: localStorage["target"],
            roles: localStorage["roles"],
            type: localStorage["contractType"],
            key: localStorage["contractKey"]
          }
      });
      return next.handle(request)      
        .pipe(
          map((event) => {
            return event;
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                this.auth.startAuthentication();
            }
            if (err.status === 403) {    
                this.router.navigateByUrl("/forbidden");
            }
            return throwError( err );
          }), 
          finalize(() => {})
        );
    }
      
  }
}
