import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { DataTablesResponse } from '../../models/common';
import { NotificationModel } from '../models/notification';


@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  private pathAPI = this.config.setting['PathAPIInfogroup'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any, idBrand:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwNotification/GetDataTable/' + idBrand, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwNotification/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public get(id: string): Observable<NotificationModel> {
    return this.http.get<NotificationModel>(this.pathAPI + 'VwNotification/' + id);
  }

  public GetVwNotificationLst(page:number): Observable<NotificationModel[]> {
    return this.http.get<NotificationModel[]>(this.pathAPI + 'VwNotification/GetVwNotificationLst/' + page);
  }

  public GetVwNotificationCount(): Observable<number> {
    return this.http.get<number>(this.pathAPI + 'VwNotification/GetVwNotificationCount/');
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwNotification', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwNotification/' + id, data);
  }

  public updateNotificationUser(idNotification: string): Observable<any> {
    return this.http.get<any>(this.pathAPI + 'VwNotification/UpdateNotificationUser/' + idNotification);
  }

  
}
