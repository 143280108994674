import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import { NotificationService } from '../../../services/notification.service';
import { NotificationModel } from '../../../models/notification';
import { AppConfig } from '../../../../config/config';
import { DocumentService } from '../../../services/document.service';
import { LastUpdatesModelDTO } from '../../../models/lastupdates';

declare var $: any;

@Component({
  selector: 'app-header-emseat',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  showTabDownloads = false;
  listDownloads: LastUpdatesModelDTO[] = [];
  countDownloadsCurrent = 0;
  data: NotificationModel[] = [];
  countNotification:number=0;
  page:number=0;
  showMore:boolean=true;
  loading:boolean=true;
  private pathWeb = this.config.setting['PathWeb'];

  constructor(
    private notificationService: NotificationService,
     private router: Router,
     private documentService: DocumentService, 
     private config: AppConfig) {
    this.getCountNotification();
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.getCountNotification();
       }
    });


    this.documentService.listDownloads.subscribe(b => {
      const exist = this.listDownloads.filter(i=> i.IdUpdate == b.IdUpdate).length > 0 ? true: false;
      if(!exist){
        this.listDownloads.push(b);
      }
      let id = setInterval(() => {
        this.countDownloadsCurrent =  this.listDownloads.filter(i => i.Download == true).length;
        this.listDownloads.forEach((element,index)=>{
          if(element.Download == false)  this.listDownloads.splice(index,1);
       });
      }, 2000);
    })
    this.documentService.changeOpenTabViewDownload.subscribe(i => {
      this.showTabDownloads = i;
    })
  }

  ngOnInit(): void {

  }

  resetData() {
    this.data = [];
    this.page = 0;
    this.showMore = true;
    this.loading = true;
  }

  getContentNotification() {
    const that = this;
    if(that.showMore == true) {
      this.notificationService.GetVwNotificationLst(that.page).subscribe(resp => {
        Array.prototype.push.apply(that.data, resp);
        that.showMore = (that.data.length != that.countNotification);
        that.loading = false;
      }, (err) => {
  
        Swal.fire({
          icon: 'error',
          title: 'Error al recuperar los datos',
          text: 'Ocurrió un error'
        });
  
      });
    }
  }
  
  getCountNotification() {
    const that = this;
    this.resetData();
    this.getContentNotification();
    this.notificationService.GetVwNotificationCount().subscribe(resp => {
      this.countNotification = resp;
    }, (err) => {

      Swal.fire({
        icon: 'error',
        title: 'Error al recuperar los datos',
        text: 'Ocurrió un error'
      });

    });
  }

  loadMore() {
    this.page ++;
    this.getContentNotification();
  }

  gotoInfogroup() {
    window.location.href = this.pathWeb + "home";
  }

  closeNotification(idNotification) {
    this.notificationService.updateNotificationUser(idNotification).subscribe(resp => {
      this.getCountNotification();
    });
  }


}
