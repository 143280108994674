import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { Select2OptionData } from 'ng-select2';


@Injectable({
  providedIn: 'root'
})

export class CommonService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public GetMenu(): Observable<any> {
    return this.http.get<any>(this.pathAPI + 'Shared/GetMenu');
  }

  public GetEncrypt(data: string): Observable<any> {
    return this.http.get<any>(this.pathAPI + 'Shared/GetEncrypt/' + data);
  }

  public GetDecrypt(data: string): Observable<any> {
    return this.http.get<any>(this.pathAPI + 'Shared/GetDecrypt/' + data);
  }

  public GetBrand(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwBrand');
  }

  public GetVwGalleryType(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwGalleryType');
  }

  public GetCategoriesGallery(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetCategoriesGallery');
  }

  public GetFilter(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwFilter');
  }

  public GetCategory(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwCategories');
  }

  public GetBusinessArea(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwBusinessArea');
  }
  public GetVwDistributorType(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwDistributorType');
  }

  public GetVwRol(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwRol');
  }

  public GetVwGrpRol(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwGrpRol');
  }

  public GetVwDistributor(): Observable<Array<Select2OptionData>> {
    return this.http.get<Array<Select2OptionData>>(this.pathAPI + 'Shared/GetVwDistributor');
  }

  public GetVwIsInternal(): Observable<any> {
    return this.http.get<any>(this.pathAPI + 'Shared/GetVwIsInternal');
  }
}
