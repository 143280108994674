import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'not-found',
    template: '<h1>Not found component</h1>'
})

export class NotFoundComponent implements OnInit{
    constructor(
        private route: ActivatedRoute,
        private router: Router
      ) {}
  ngOnInit() {
    // debugger;
    // this.route.queryParams.subscribe(params => {
    //   if(params !== null && params.CONTRACT_KEY && params.CONTRACT_TYPE && params.ROLES && params.Target){
    //     localStorage.setItem("contractKey", params.CONTRACT_KEY);
    //     localStorage.setItem("contractType", params.CONTRACT_TYPE);
    //     localStorage.setItem("roles", params.ROLES);
    //     localStorage.setItem("target", params.Target);
    //     this.router.navigateByUrl("/home");
    //   }
    // });
  }

}
