<div class="container__logo" *ngIf="menu == null">
    <div class="logo__ig">
        <img src="../../../assets/img/Infogroup.svg" alt="Infogroup">
    </div>
    <div class="loading">
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<!--Sidebar Main Menu-->
<aside class="main-sidebar fixed offcanvas shadow no-b" *ngIf="menu != null">
    <section class="sidebar">
        <div class="relative">
            <div class="main-sidebar__heading">
                <p class="heading-brand">EasyMarketing</p>
                <a href="#" data-toggle="push-menu" class="heading__close"></a>
            </div>
            <div class="main-sidebar__user">
                <div class="d-flex flex-column" *ngIf="user != undefined">
                    <p class="subheading-name">{{user.Name}} {{user.Surname}}</p>
                    <p class="subheading-position">{{user.Distributor.SocialName}}</p>
                    <p class="subheading-position">{{user.GrpRol.RolName}}</p>
                </div>
                <div class="justify-content-end avatar__container">
                    <a (click)="logout()" class="cursor-pointer">
                        <div class="avatar"></div>
                        <div class="d-flex">
                            <span class="subheading-logout">Salir</span>
                            <i class="logout ml-1"></i>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <!--Tree menu-->
        <ul class="sidebar-menu" menu-emseat *ngIf="menu != null" [items]="menu.Items">
        </ul>
    </section>
</aside>

<!--Cabecera-->
<!-- <div class="sticky-header has-sidebar-left" *ngIf="menu != null"> -->
<div class="sticky-header has-sidebar-left" [hidden]="menu == null">
    <div class="pos-f-t">
        <div class="collapse" id="navbarToggleExternalContent">
            <div class="bg-search pl-3 pr-3">
                <div class="search-bar">
                    <app-searcheasymarketing></app-searcheasymarketing>
                </div>
                <a href="#" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation" class="paper-nav-toggle paper-nav-white active "><i></i></a>
            </div>
        </div>
    </div>

    <!--Header-->

    <div class="navbar navbar-expand navbar__bg border-bottom">
        <div class="d-flex justify-content-between w-100">
            <div class="navbar__logo-container">
                <div class="d-flex align-items-center">
                    <a class="navbar__menu" href="#" data-toggle="push-menu"></a>
                    <div class="logo__brand"></div>
                </div>
            </div>
            <div class="navbar-custom-menu">
                <app-header-emseat></app-header-emseat>
            </div>
        </div>
    </div>
</div>

<!--Contenido-->
<div class="page has-sidebar-left" *ngIf="menu != null">
    <div class="mt-content height-full bg-soft animatedParent animateOnce" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
<app-footer [hidden]="menu == null"></app-footer>
