import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from '../../../services/search.service';
import { DocumentService } from '../../../services/document.service';
import { SearchModel } from '../../../models/search';
import { FichaTecnicaService } from '../../../services/fichatecnica.service';
import { ImageBankService } from '../../../services/imagebank.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-searcheasymarketing',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @ViewChild("modalSearchDocument") modal: ElementRef;
  searchText: string;
  searchResult: SearchModel = new SearchModel();
  termTypeCode: string;
  currentPage: number;
  pages: number[];
  itemsPerPage: number;
  loading: boolean = true;
  noResult: boolean = false;
  DocumentBlob: Blob;

  constructor(private modalService: NgbModal,
    private searchService: SearchService,
    private fichatecnicaService: FichaTecnicaService,
    private imagebankService: ImageBankService,
    private toastr: ToastrService,
    private documentService: DocumentService) {
    this.currentPage = 1;
    this.pages = new Array(0);
    this.itemsPerPage = 5;
  }

  ngOnInit(): void {
  }

  filterTermTypeCode(value) {
    if (value == this.termTypeCode) {
      this.termTypeCode = '';
    } else {
      this.termTypeCode = value;
    }

    // Reset search data
    this.searchResult = new SearchModel();
    this.currentPage = 0;
    this.pages = new Array(0);

    this.loading = true;
    this.noResult = false;

    // Search
    this.searchService.searchTerm(this.searchText, 1, this.itemsPerPage, this.termTypeCode).subscribe((searchModel) => {
      this.loading = false;
      this.searchResult = searchModel;
      if (this.searchResult.items.length == 0) {
        this.noResult = true;
      }
      this.currentPage = 0;
      this.pages = new Array(Math.ceil(this.searchResult.total_item / this.itemsPerPage));
    });
  }

  searchByKey(event) {
    if (event.key === "Enter") {
      this.searchContent(event.target.value);
    }
  }

  searchByClick() {
    this.searchContent($('#seachDocument').val());
  }

  searchContent(textToSearch) {
    if (textToSearch == null || textToSearch == '') {
      return '';
    }


    // Reset search data
    this.termTypeCode = '';
    this.searchResult = new SearchModel();
    this.currentPage = 0;
    this.pages = new Array(0);

    // Ketp new search term
    this.searchText = textToSearch;

    this.loading = true;
    this.noResult = false;

    // Search
    this.searchService.searchTerm(this.searchText, 1, this.itemsPerPage, this.termTypeCode).subscribe((searchModel) => {
      this.loading = false;
      // Kept search result
      this.searchResult = searchModel;
      // Check results
      if (this.searchResult.items.length == 0) {
        this.noResult = true;
      }

      // Update paging data
      this.currentPage = 0;
      this.pages = new Array(Math.ceil(this.searchResult.total_item / this.itemsPerPage));
    });

    //this.mensajeInicial = mensajeInicial;
    this.modalService.open(this.modal, {
      size: 'lg',
    });
  }

  pagingSearch(nextPage) {

    if (nextPage >= 0 && nextPage <= this.pages.length) {
      // Reset search data
      this.searchResult = new SearchModel();

      this.loading = true;
      this.noResult = false;

      this.searchService.searchTerm(this.searchText, (nextPage * this.itemsPerPage) + 1, this.itemsPerPage, this.termTypeCode).subscribe((searchModel) => {
        this.loading = false;

        // Kept search result
        this.searchResult = searchModel;
        // Check results
        if (this.searchResult.items.length == 0) {
          this.noResult = true;
        }

        // Update paging data
        this.currentPage = nextPage;
        this.pages = new Array(Math.ceil(this.searchResult.total_item / this.itemsPerPage));
      });
    }

  }

  downloadFichaTecnica(id: string, name: string) {
    const toast = this.toastr.success("Se está descargando un archivo", "Descarga de archivo", { disableTimeOut: true });
    this.fichatecnicaService.getFichaTecnicaFile(id).subscribe((fileDoc) => {
      this.DocumentBlob = fileDoc;
      this.toastr.remove(toast.toastId);
      saveAs(this.DocumentBlob, name);
      this.fichatecnicaService.trackFichaTecnica(id).subscribe();
    }, (err) => {
      this.toastr.remove(toast.toastId);
      this.toastr.error("No se pudo descargar el  archivo", "Descarga de archivo", { timeOut: 2000 });
    });
  }


  downloadDocument(id: string, name: string) {
    const toast = this.toastr.success("Se está descargando un archivo", "Descarga de archivo", { disableTimeOut: true });
    this.documentService.getDocumentFile(id).subscribe((fileDoc) => {
      this.DocumentBlob = fileDoc;
      this.toastr.remove(toast.toastId);
      saveAs(this.DocumentBlob, name);
      this.documentService.trackDocument(id, 2).subscribe();
    }, (err) => {
      this.toastr.remove(toast.toastId);
      this.toastr.error("No se pudo descargar el  archivo", "Descarga de archivo", { timeOut: 2000 });
    });
  }

  downloadMultimedia(id: string, name: string) {
    const toast = this.toastr.success("Se está descargando un archivo", "Descarga de archivo", { disableTimeOut: true });
    this.imagebankService.getGalleryFile(id).subscribe((fileDoc) => {
      this.DocumentBlob = fileDoc;
      this.toastr.remove(toast.toastId);
      saveAs(this.DocumentBlob, name);
      this.imagebankService.trackGallery(id).subscribe();
    }, (err) => {
      this.toastr.remove(toast.toastId);
      this.toastr.error("No se pudo descargar el  archivo", "Descarga de archivo", { timeOut: 2000 });
    });
  }


  linkSection(id: string) {
    let urlSection = '/section/' + encodeURIComponent(id);
    //let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(urlSection);
    return urlSection;
  }
}
