import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NotificationService } from '../../../services/notification.service';
import { NotificationModel } from '../../../models/notification';
import { UserModel } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { AppConfig } from '../../../../config/config';
import { LastUpdatesModelDTO } from 'src/app/Infogroup-app/models/lastupdates';
import { DocumentService } from 'src/app/Infogroup-app/services/document.service';
import { Subject } from 'rxjs';
import { Timeouts } from 'selenium-webdriver';

declare var $: any;

@Component({
  selector: 'app-header-infogroup',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {
  data: NotificationModel[] = [];
  countNotification: number = 0;
  page: number = 0;
  id: any;
  showMore: boolean = true;
  loading: boolean = true;
  user: UserModel;
  private pathWeb = this.config.setting['PathWeb'];
  showEasymarketing = false;


  showTabDownloads = false;
  listDownloads: LastUpdatesModelDTO[] = [];
  countDownloadsCurrent = 0;



  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: UserService,
    private config: AppConfig,
    private documentService: DocumentService) {

    this.route.queryParams.subscribe(params => {
      if (params !== null && params.CONTRACT_KEY && params.CONTRACT_TYPE && params.ROLES && params.Target) {
        localStorage.setItem("contractKey", params.CONTRACT_KEY);
        localStorage.setItem("contractType", params.CONTRACT_TYPE);
        localStorage.setItem("roles", params.ROLES);
        localStorage.setItem("target", params.Target);
      }
    });

    this.getCountNotification();
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.getCountNotification();
      }
    });
      
    this.documentService.listDownloads.subscribe(b => {
      const exist = this.listDownloads.filter(i=> i.IdUpdate == b.IdUpdate).length > 0 ? true: false;
      if(!exist){
        this.listDownloads.push(b);
      }
      this.id = setInterval(() => {
        this.countDownloadsCurrent =  this.listDownloads.filter(i => i.Download == true).length;
        this.listDownloads.forEach((element,index)=>{
          if(element.Download == false)  this.listDownloads.splice(index,1);
       });
      }, 2000);
    })
    this.documentService.changeOpenTabViewDownload.subscribe(i => {
      this.showTabDownloads = i;
    })

  }
  ngOnDestroy(): void {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  ngOnInit(): void {
    this.authService.getUserInfo().subscribe(resp => {
      this.user = resp;
      this.showEasymarketing = this.user.Brand.IdBrand == 1 || this.user.Brand.IdBrand == 2;      
    });
  }


  resetData() {
    this.data = [];
    this.page = 0;
    this.showMore = true;
    this.loading = true;
  }

  getContentNotification() {
    const that = this;
    if (that.showMore == true) {
      this.notificationService.GetVwNotificationLst(that.page).subscribe(resp => {
        Array.prototype.push.apply(that.data, resp);
        that.showMore = (that.data.length != that.countNotification);
        that.loading = false;
      }, (err) => {
          Swal.fire({
            icon: 'info',
            title: 'Cargando...',
            text: 'Espere por favor'
          });

      });
    }
  }

  getCountNotification() {
    const that = this;
    this.resetData();
    this.getContentNotification();
    this.notificationService.GetVwNotificationCount().subscribe(resp => {
      this.countNotification = resp;
    }, (err) => {

        Swal.fire({
          icon: 'info',
          title: 'Cargando...',
          text: 'Espere por favor'
        });

    });
  }

  loadMore() {
    this.page++;
    this.getContentNotification();
  }

  goToNotificationLink(notification) {
    if (notification.Url != null) {      
      if (notification.Url.includes("http") || notification.Url.includes("https")) {        
        if (notification.Url.includes(this.pathWeb)) {          
          let finalUrl = notification.Url.replace(this.pathWeb, "/");
          this.router.navigate([finalUrl]);
        } else {          
          window.location.href = notification.Url;
        }
      } else {        
        this.router.navigate([notification.Url]);
      }
    }
    this.closeNotification(notification.IdNotification);
  }

  closeNotification(idNotification) {
    this.notificationService.updateNotificationUser(idNotification).subscribe(resp => {
      this.getCountNotification();
    });
  }

  gotoEasymarketing() {
    window.location.href = this.pathWeb + "easymarketing";
  }

}
