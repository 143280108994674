<ng-container *ngFor="let item of items">
    <li *ngIf="!item.SubItems || item.SubItems.length == 0">
        <a href="javascript:void(0);" routerLink="{{item.Url}}" routerLinkActive="active-menu">
            <i *ngIf="item.Class && item.Class.length > 0" [class]="item.Class"></i> <span>{{item.Title}}</span>
        </a>
    </li>
    <li class="treeview" *ngIf="item.SubItems && item.SubItems.length > 0">
        <a href="javascript:void(0);">
            <i *ngIf="item.Class && item.Class.length > 0" [class]="item.Class"></i> <span>{{item.Title}}</span> <i class="icon icon-angle-left s-18 pull-right"></i>
        </a>
        <ul class="treeview-menu" menu-emseat [items]="item.SubItems">
        </ul>
    </li>
</ng-container>