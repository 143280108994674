import {sequence, trigger, stagger, animate, style, group, query as q, transition, keyframes, animateChild} from '@angular/animations';
const query = (s,a,o={optional:true})=>q(s,a,o);
    
  // Routable animations
export const slideInAnimation =
   trigger('routeAnimations', [
     transition('* <=> *', [
       style({ opacity: 0 }),
       animate('500ms ease-in-out', style({ opacity: 1 }))
     ])
   ]);
