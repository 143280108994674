import { Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard';

export const easymarketingroutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
    data: { animation: 'home', title: 'EasyMarketing' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'campaign',
    loadChildren: () => import('./components/campaign/campaign.module').then(m => m.CampaignModule),
    data: { animation: 'camp' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'imagebank',
    loadChildren: () => import('./components/imagebank/imagebank.module').then(m => m.ImageBankModule),
    data: { animation: 'imagebank' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'document/:id',
    loadChildren: () => import('./components/document/document.module').then(m => m.DocumentModule),
    data: { animation: 'doc' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/banner',
    loadChildren: () => import('./components/Admin/banner/banner.module').then(m => m.AdmBannerModule),
    data: { animation: 'admb' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/carousel',
    loadChildren: () => import('./components/Admin/carousel/carousel.module').then(m => m.AdmCarouselModule),
    data: { animation: 'admc' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/filter',
    loadChildren: () => import('./components/Admin/filter/filter.module').then(m => m.AdmFilterModule),
    data: { animation: 'admfilter' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/campaign',
    loadChildren: () => import('./components/Admin/campaign/campaign.module').then(m => m.AdmCampaignModule),
    data: { animation: 'adcamp' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/tailored-campaign',
    loadChildren: () => import('./components/Admin/tailored-campaign/tailored-campaigns.module').then(m => m.AdmTailoredCampaignModule),
    data: { animation: 'adcamp' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/materiales',
    loadChildren: () => import('./components/Admin/material/material.module').then(m => m.AdmMaterialModule),
    data: { animation: 'adcamp' }
  },
  {
    path: 'adm/fichatecnica',
    loadChildren: () => import('./components/Admin/fichatecnica/fichatecnica.module').then(m => m.AdmFichaTecnicaModule),
    data: { animation: 'adcamp' }
  },
  {
    path: 'adm/document',
    loadChildren: () => import('./components/Admin/document/document.module').then(m => m.AdmDocumentModule),
    data: { animation: 'admdo' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/news',
    loadChildren: () => import('./components/Admin/news/news.module').then(m => m.AdmNewsModule),
    data: { animation: 'admn' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/faq',
    loadChildren: () => import('./components/Admin/faq/faq.module').then(m => m.AdmFaqModule),
    data: { animation: 'adfaq' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/videotutorial',
    loadChildren: () => import('./components/Admin/videotutorial/videotutorial.module').then(m => m.AdmVideoTutorialModule),
    data: { animation: 'advideo' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/notification',
    loadChildren: () => import('./components/Admin/notification/notification.module').then(m => m.AdmNotificationModule),
    data: { animation: 'admno' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/mailinglist',
    loadChildren: () => import('./components/Admin/mailinglist/mailinglist.module').then(m => m.AdmMailingListModule),
    data: { animation: 'admailinglist' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/imagebank',
    loadChildren: () => import('./components/Admin/imagebank/imagebank.module').then(m => m.AdmImageBankModule),
    data: { animation: 'admimagebank' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'ayuda/faq',
    loadChildren: () => import('./components/Ayuda/faq/faq.module').then(m => m.FaqModule),
    data: { animation: 'ayfaq' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/distributor',
    loadChildren: () => import('./components/Admin/distributor/distributor.module').then(m => m.AdmDistributorModule),
    data: { animation: 'admdis' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/rol',
    loadChildren: () => import('./components/Admin/rol/rol.module').then(m => m.AdmRolModule),
    data: { animation: 'admro' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/statistics',
    loadChildren: () => import('./components/Admin/statistics/statistics.module').then(m => m.StatisticsModule),
    data: { animation: 'admro' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'ayuda/videotutorial',
    loadChildren: () => import('./components/Ayuda/videotutorial/videotutorial.module').then(m => m.VideoTutorialModule),
    data: { animation: 'ayvideotutorial' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'material',
    loadChildren: () => import('./components/material/material.module').then(m => m.MaterialModule),
    data: { animation: 'mat' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'documentcard/:id',
    loadChildren: () => import('./components/document-card/document-card.module').then(m => m.DocumentCardModule),
    data: { animation: 'mat' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'dealermarketingplan',
    loadChildren: () => import('./components/dealer-marketing-plan/dealer-marketing-plan.module').then(m => m.DealerMarketingPlanModule),
    data: { animation: '' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/dealermarketingplan',
    loadChildren: () => import('./components/Admin/dealer-marketing-plan/dealer-marketing-plan.module').then(m => m.AdminDealerMarketingPlanModule),
    data: { animation: 'admro' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/accionesmarketing',
    loadChildren: () => import('./components/Admin/acciones-marketing/acciones-marketing.module').then(m => m.AdminAccionesMarketingModule),
    data: { animation: 'admro' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'accionesmarketing',
    loadChildren: () => import('./components/acciones-marketing/acciones-marketing.module').then(m => m.AccionesMarketingModule),
    data: { animation: '' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'controlreembolsos',
    loadChildren: () => import('./components/control-reembolso-audi/control-reembolso-audi.module').then(m => m.ControlReembolsoAudiModule),
    data: { animation: '' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/controlreembolsos',
    loadChildren: () => import('./components/Admin/control-reembolso-audi/control-reembolso-audi.module').then(m => m.AdminControlReembolsoAudiModule),
    data: { animation: 'admro' },
    canActivate: [AuthGuardService]
  }
];


