import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app',
  template: '<router-outlet></router-outlet>'
})

export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document) {
    }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (window.location.href.indexOf('/easymarketing/') > -1) {
        this.document.body.classList.add('easymarketing');
        this.document.body.classList.remove('infogroup');
        this.document.title = 'EasyMarketing';
      } else {
        this.document.body.classList.remove('easymarketing');
        this.document.body.classList.add('infogroup');
        this.document.title = 'Infogroup';
      }
      if (window.location.href.indexOf('/adm/') > -1) {
        this.document.body.classList.add('admin');
      } else {
        this.document.body.classList.remove('admin');
      }
      window.scrollTo(0, 0);
    });
  }
}
