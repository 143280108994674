import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class dateFormatPipe implements PipeTransform {

  transform(value: Date | moment.Moment, dateFormat: string): any {
    
    return moment(value).format(dateFormat)
  }
}