import { Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard';


export const infogroupRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
    data: { animation: 'home' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'ayuda/faq',
    loadChildren: () => import('./components/Ayuda/faq/faq.module').then(m => m.FaqModule),
    data: { animation: 'ayfaq' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'ayuda/videotutorial',
    loadChildren: () => import('./components/Ayuda/videotutorial/videotutorial.module').then(m => m.VideoTutorialModule),
    data: { animation: 'ayvideotutorial' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/banner',
    loadChildren: () => import('./components/Admin/banner/banner.module').then(m => m.AdmBannerModule),
    data: { animation: 'admb' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/carousel',
    loadChildren: () => import('./components/Admin/carousel/carousel.module').then(m => m.AdmCarouselModule),
    data: { animation: 'admc' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/filter',
    loadChildren: () => import('./components/Admin/filter/filter.module').then(m => m.AdmFilterModule),
    data: { animation: 'admfilter' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/menu',
    loadChildren: () => import('./components/Admin/menu/menu.module').then(m => m.AdmMenuModule),
    data: { animation: 'admm' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/news',
    loadChildren: () => import('./components/Admin/news/news.module').then(m => m.AdmNewsModule),
    data: { animation: 'admn' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/faq',
    loadChildren: () => import('./components/Admin/faq/faq.module').then(m => m.AdmFaqModule),
    data: { animation: 'adfaq' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/videotutorial',
    loadChildren: () => import('./components/Admin/videotutorial/videotutorial.module').then(m => m.AdmVideoTutorialModule),
    data: { animation: 'advideo' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/notification',
    loadChildren: () => import('./components/Admin/notification/notification.module').then(m => m.AdmNotificationModule),
    data: { animation: 'admno' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/rol',
    loadChildren: () => import('./components/Admin/rol/rol.module').then(m => m.AdmRolModule),
    data: { animation: 'admro' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/section',
    loadChildren: () => import('./components/Admin/section/section.module').then(m => m.AdmSectionModule),
    data: { animation: 'admse' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/document',
    loadChildren: () => import('./components/Admin/document/document.module').then(m => m.AdmDocumentModule),
    data: { animation: 'admdo' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/distributor',
    loadChildren: () => import('./components/Admin/distributor/distributor.module').then(m => m.AdmDistributorModule),
    data: { animation: 'admdis' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'section',
    loadChildren: () => import('./components/section/section.module').then(m => m.SectionModule),
    data: { animation: 'section' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'adm/statistics',
    loadChildren: () => import('./components/Admin/statistics/statistics.module').then(m => m.StatisticsModule),
    data: { animation: 'admstat' },
    canActivate: [AuthGuardService]
  }
];
