import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { VwFichaTecnicaDTO } from '../models/fichatecnica';

@Injectable({
  providedIn: 'root'
})

export class FichaTecnicaService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwFichaTecnica/GetDataTable', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwFichaTecnica/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public searchDataOrder(data: any, elements:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwFichaTecnica/GetOrderData/' + elements, data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwFichaTecnica/ChangeOrder', data);
  }
  
  public getall(): Observable<VwFichaTecnicaDTO[]> {
    return this.http.get<VwFichaTecnicaDTO[]>(this.pathAPI + 'VwFichaTecnica/VwVideoTutorialAll');
  }

  public get(id: string): Observable<VwFichaTecnicaDTO> {
    return this.http.get<VwFichaTecnicaDTO>(this.pathAPI + 'VwFichaTecnica/' + id);
  }

  public saveFichaTecnicaFiles(fileDocument: File, fileImage:File): Observable<any> {
    let formData: FormData = new FormData();

    if (fileDocument != undefined) {
      formData.append('fileDocument', fileDocument, fileDocument.name);
    }

    if (fileImage != undefined) {
      formData.append('fileImage', fileImage, fileImage.name);
    }
    return this.http.post<any>(this.pathAPI + 'VwFichaTecnica/SaveFichaTecnicaFiles', formData);
  }

  public getFichaTecnicaFile(idFichatecnica: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwFichaTecnica/GetFichaTecnicaFile/' + idFichatecnica;
    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwFichaTecnica', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwFichaTecnica/' + id, data);
  }

  public getFichaTecnicaListHome(): Observable<VwFichaTecnicaDTO[]> {
    return this.http.get<VwFichaTecnicaDTO[]>(this.pathAPI + 'VwFichaTecnica/GetFichaTecnicaHome');
  }

  public trackFichaTecnica(idFichatecnica: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwFichaTecnica/TrackFichaTecnica/' + idFichatecnica, null);
  }
 
}
